<template>
  <div class="blog-card spring-fever" :style="{ backgroundImage: `url(${imageUrl})` }">
    <div class="title-content">
      <h1 class="fs-5"><a href="#">{{title}}</a></h1>
    </div>
    <div class="card-info">
      <span>
        {{ description }}
      </span>
      <a :href="link" class="btn10 rounded col-4 col-xxl-3 col-md-6 col-lg-3 col-xl-3 col-md-3 d-flex justify-content-center" >Demo</a>
    </div>

    <div class="gradient-overlay"></div>
    <div class="color-overlay"></div>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    imageUrl: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.card-info span {
  font-size: 14px;
}
.blog-card {
  max-width: 550px;
  width: 300px;
  height: 170px;
  position: relative;
  color: #fff;
  overflow: hidden;
  border-radius: 0px;
  box-shadow: 0px 10px 20px -9px rgba(0, 0, 0, 0.5);
  text-align: center;
  transition: all 0.4s;
  background: v-bind center no-repeat;
  background-size: 100%;
}

.blog-card a {
  color: #fff;
  text-decoration: none;
  transition: all 0.2s;
}

.title-content h1 {
  color: white; /* Color del texto */
  text-shadow: 
    1px 1px 0px black, 
    -1px -1px 0px black, 
    1px -1px 0px black, 
    -1px 1px 0px black; /* Crea el efecto de borde negro */
  font-weight: bold;
}

.blog-card .color-overlay {
  background: rgba(64, 84, 94, 0.658);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  transition: background 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
}
.blog-card:hover .utility-info,
.blog-card:hover .title-content h3 {
  opacity: 0;
  transform: scale(0.9);
  visibility: hidden;
  transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
}

.blog-card .gradient-overlay {
  background-image: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.6) 21%);
  width: 100%;
  height: 500px;
  position: absolute;
  top: 350px;
  left: 0;
  z-index: 15;
}

.blog-card:hover {
  box-shadow: 0px 18px 20px -9px rgba(0, 10, 30, 0.75);
}

.blog-card:hover .card-info {
  opacity: 1;
}

.blog-card:hover .color-overlay {
  background: rgba(64, 64, 70, 0.8);
}

.blog-card:hover .title-content {
  margin-top: 70px;
}

.title-content {
  text-align: center;
  margin: 160px 0 0 0;
  position: absolute;
  z-index: 20;
  width: 100%;
  top: -30px;
  left: 0;
  transition: all 0.6s;
}

.blog-card:hover h3:after {
  animation: changeLetter 0.3s 1 linear;
  width: 80%;
}

.blog-card h3 {
  font-size: 1.9em;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 0;
  display: inline-block;
}

.blog-card h3 a {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.2s;
}

.blog-card h3 a:hover {
  text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.95);
}


.btn10 {
  padding: 3px!important;
  top: 10px!important;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase!important;
  letter-spacing: 2px;
  text-decoration: none;
  color: #fff!important;
  cursor: hand;
  text-align: center;
  border: 1px solid black!important;
  position: relative;
  overflow: hidden!important;
  text-align: center!important;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  background-color: rgb(0, 0, 0)!important;
  z-index: 10;
}

.btn10:hover {
  border: 1px solid rgb(0, 0, 0);
  color: #000000!important;
}

.btn10::before,
.btn10::after {
  content: '';
  width: 0%;
  height: 100%;
  display: block;
  position: absolute;
  -ms-transform: skewX(-20deg);
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
  left: -10%;
  top: 0;
  box-shadow: 2px 0px 14px rgb(0, 0, 0);
}

.btn10::before {
  background: #fcb206;
  z-index: -12;
  opacity: 1;
  -moz-transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
}

.btn10::after {
  background: #b98a1d;
  z-index: -15;
  opacity: 0;
  -webkit-transition: all .94s cubic-bezier(.2,.95,.57,.99);
  -moz-transition: all .4s cubic-bezier(.2,.95,.57,.99);
  -o-transition: all .4s cubic-bezier(.2,.95,.57,.99);
  transition: all .4s cubic-bezier(.2,.95,.57,.99);
}

.btn10:hover::before,
.btn10:hover::after {
  opacity: 1;
  width: 116%;
}

.btn10:hover::after,
.btn10:hover::after {
  opacity: 1;
  width: 120%;
}

@keyframes changeLetter {
  0% {
    width: 10%;
  }
  100% {
    width: 80%;
  }
}

.intro a {
  color: #ddd;
}

.intro a:hover {
  text-decoration: underline;
}

.card-info {
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0;
  margin: 0 auto;
  padding: 0 50px;
  font-style: 16px;
  line-height: 24px;
  z-index: 20;
  opacity: 0;
  transition: bottom 0.64s, opacity 0.63s cubic-bezier(0.33, 0.66, 0.66, 1);
}

.card-info a {
  display: block;
  width: 100px;
  margin: 15px auto;
  background: #fff;
  color: #444;
  padding: 0px;
  border-radius: 2px;
  font-size: 0.8em;
}

.card-info a:hover {
  background: #8e7c49;
  color: #fff;
}

.utility-info {
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 20;
  width: 100%;
  text-align: left;
}

.utility-list {
  list-style-type: none;
  margin: 0 0 10px 20px;
  padding: 0;
  width: 100%;
}

.utility-list li {
  margin: 0 5px 0 0;
  padding: 3px 0 15px 0;
  display: inline-block;
  font-size: 0.8em;
}

.licon {
  position: relative;
  top: 300px;
  width: 23px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 750px) {
  .utility-info {
    text-align: center;
  }

  .utility-info ul {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }

  .utility-info li {
    width: 49%;
    display: inline-block;
    box-sizing: border-box;
    margin: 0;
  }
}

@media (max-width: 500px) {
  .utility-info li:last-of-type {
    width: 100%;
  }

  .card-info {
    display: none;
  }

  .blog-card:hover .title-content,
  .title-content {
    margin-top: 40px;
  }

  .blog-card {
    height: 300px;
  }

  .blog-card h3 {
    font-size: 1.3em;
  }

  .intro {
    font-size: 0.8em;
  }
}
</style>
